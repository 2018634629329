// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import {getFirestore} from "firebase/firestore"
import {getDatabase} from "firebase/database"
import { ref } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDceXY2J5IcykpfRQ75fMc6E9Pq-Hbcvw8",
  authDomain: "connect-app-a3153.firebaseapp.com",
  projectId: "connect-app-a3153",
  storageBucket: "connect-app-a3153.appspot.com",
  messagingSenderId: "190677659096",
  appId: "1:190677659096:web:f677a6052516a49a9216ad",
  measurementId: "G-50YJF1G82K"
};

// Initialize Firebase
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const dbf = getFirestore(app)
export const dbr = getDatabase(app)
export const imageRef = ref