// App.js
import React, { useEffect, useState } from 'react';
// import './App.css';
import Navbar from './components/Navigation';
import { Route, Routes } from 'react-router-dom';
import Login from './components/Auth/Login';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from './components/Auth/Loader';
import WebVoucher from './components/WebVoucher';
import SuccessPage from './components/successPage/SuccessPage';
import SearchVouher from './components/SearchVouher';

function App() {
  const [firebaseLoaded, setFirebaseLoaded] = useState(true);

  useEffect(() => {
    // Simulating Firebase initialization
    setTimeout(() => {
      // Set firebaseLoaded to true after Firebase finishes loading
      setFirebaseLoaded(false);
    }, 3000); // Replace this with your actual Firebase initialization code
  }, []);

  if (firebaseLoaded) {
    return <Loader />
  }

  return (
    <>
      <Routes>
        <Route path='/' element={<WebVoucher />} />
        <Route path='/apply/success' element={<SuccessPage />} />
        <Route path='/search/print/:search_id?' element={<SearchVouher />} />
        <Route path='*' element={<center>
          <h1>404</h1>
        </center>} />
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
