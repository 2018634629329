import React from 'react';
import './SuccessPage.css'; // Import the CSS file for styling

const SuccessPage = () => {
  return (
    <div className="success-page">
      <h2 className="success-page__title">Voucher Submission Successful!</h2>
      <p className="success-page__message">Thank you for submitting your voucher to Hamsyl Travel and Tour Ltd. Your voucher has been received and will be processed.</p>
      <p className="success-page__message">We will get back to you with confirmation and any additional details if needed.</p>
    </div>
  );
};

export default SuccessPage;
