import React, { useEffect, useState } from 'react'
import { fetchLatestData } from './customApi/customFile'
import { useParams } from 'react-router-dom'
import PdfFile from './generator/PdfFile'
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer'
import { Card, CardHeader } from 'reactstrap'

export default function SearchVouher() {
    const [results, setResults] = useState()
    const params = useParams()
    // console.log()

    useEffect(() => {
        // web/search/voucher/:search_id?
        fetchLatestData(
            `web/search/voucher/${params.search_id}`,
            {},
            (data) => {
                setResults(data[0])

            },
            (err) => {
                console.log(err)
            }
        )
    }, [0])

    return (
        <div>
            <div className="success-page">
                {/* <h2 className="success-page__title">Voucher Submission Successful!</h2>
                    <p className="success-page__message">Thank you for submitting your voucher to Hamsyl Travel and Tour Ltd. Your voucher has been received and will be processed.</p> */}
                <p className="success-page__title">To open and download your voucher, please follow these steps:</p>
                <ol className="success-page__instructions">
                    <li>Scan the QR code using your mobile device.</li>
                    <li>If you prefer to open it on your laptop, please follow these additional steps:</li>
                    <ul>
                        <li>Open your laptop.</li>
                        <li>Visit our website and log in to your account.</li>
                        <li>Locate the "My Vouchers" section.</li>
                        <li>Find your submitted voucher and click on the {" "}
                        <PDFDownloadLink document={<PdfFile data={results} />} fileName="hamsyl-voucher.pdf">
                        {({ _blob, _url, loading, _error }) => (loading ? 'Loading document...' : 'download link.')}
                    </PDFDownloadLink>
                        </li>
                    </ul>
                </ol>
                <p className="success-page__message">If you encounter any issues or have questions, feel free to contact our customer support 
                {" "} <a href='tel: +2348032347157'>call</a></p>
                {/* <div className="success-page__message">
                   
                </div> */}
            </div>

            {/* <PDFViewer style={{ width: '100%', height: '800px' }}>
                <PdfFile data={results} />
            </PDFViewer> */}
        </div>

    )
}
