import React from 'react';
import './suspend.css'; // Import the CSS file for styling

const SuspendPage = () => {
  return (
    <div className="success-page">
      <h2 className="success-page__title">Server Suspended!</h2>
      <p className="success-page__message">We apologize, but the server is currently suspended. Please try again later</p>
      <p className="success-page__message">If the issue persists, please contact our support team for assistance.</p>
    </div>
  );
};

export default SuspendPage;
