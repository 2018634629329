// components/Form.js
import React, { useEffect, useState } from 'react';
import { Button, Col, Input, Label, Navbar, NavbarBrand, Row, Progress, InputGroup } from 'reactstrap';
import "./Form.css"
import { toast } from 'react-toastify';
// import SuccessPage from './successPage/SuccessPage';
// import Loader from './Auth/Loader';
import { FaMinusSquare, FaPlusSquare } from "react-icons/fa"
import { GoArrowLeft, GoArrowRight, } from "react-icons/go"
import { FiSave } from "react-icons/fi"
import { IoCaretBack } from "react-icons/io"
import { fetchLatestData, postNewData } from './customApi/customFile';
import { useNavigate } from 'react-router-dom';
import SuspendPage from './suspend/SuspendPage';

const WebVoucher = () => {
    // const [success, setSuccess] = useState(false)
    // const [loading, setLoading] = useState(false)
    const [currentStep, setCurrentStep] = useState(1);
    const [hotelsList, setHotelsList] = useState([])
    const [checked, setChecked] = useState("")

    const navigate = useNavigate()

    const initialFormData = {
        agentName: '',
        airline: '',
        cityMadina: 'MADINA',
        nightsMadina: '',
        roomTypeMadina: '',
        hotelMadina: '',
        checkInMadina: '',
        checkOutMadina: '',

        cityMakkah: 'MAKKAH',
        nightsMakkah: '',
        roomTypeMakkah: '',
        hotelMakkah: '',
        checkInMakkah: '',
        checkOutMakkah: '',
        transportationType: '',
        paymentType: '',
    };

    const initialPassportData = {
        name: '',
        passport: '',
    };

    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const applyAt = `${year}-${month}-${day}`

    const [formData, setFormData] = useState(initialFormData);

    const [passportData, setPassportData] = useState([initialPassportData]);


    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value.toUpperCase(), // Convert to uppercase here
        }));
    };

    const handleAddPassport = () => {
        setPassportData([...passportData, { name: '', passport: '' }]);
    };

    const handlePassportChange = (index, field, value) => {
        const updatedPassports = [...passportData];
        updatedPassports[index][field] = value.toUpperCase(); // Convert to uppercase here
        setPassportData(updatedPassports);
    };

    const handleRemovePassport = (indexToRemove) => {
        const updatedPassportData = passportData.filter((_, index) => index !== indexToRemove);
        setPassportData(updatedPassportData);
    };

    const handlePrevious = () => {
        // Move to the previous step, unless already at the first step
        setCurrentStep((prevStep) => (prevStep > 1 ? prevStep - 1 : prevStep));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (currentStep === 4) {
            if (formData.agentName === "" ||
                formData.airline === "" ||
                passportData[0].name === "" ||
                passportData[0].passport === "" ||
                formData.cityMadina === "" ||
                formData.nightsMadina === "" ||
                formData.roomTypeMadina === "" ||
                formData.hotelMadina === "" ||
                formData.checkInMadina === "" ||
                formData.checkOutMadina === "" ||
                formData.cityMakkah === "" ||
                formData.nightsMakkah === "" ||
                formData.roomTypeMakkah === "" ||
                formData.hotelMakkah === "" ||
                formData.checkInMakkah === "" ||
                formData.checkOutMakkah === "" ||
                formData.transportationType === "" ||
                formData.paymentType === ""
            ) {
                // setCurrentStep(1);
                toast("Invalid form")
            } else {
                postNewData(
                    { ...formData, passportData, applyAt },
                    "web/voucher/create",
                    (data) => {
                        toast("Successfully submited")
                        setFormData(initialFormData);
                        setPassportData([initialPassportData]);
                        navigate("/apply/success")
                    },
                    (err) => {
                        console.log("===================== err", err.message);
                        alert(err.message);
                    }
                )

                // For demonstration purposes, let's reset the form data
                setFormData(initialFormData);
                setCurrentStep(1);
            }

        } else {
            // Move to the next step
            setCurrentStep((prevStep) => prevStep + 1);
        }
    };

    useEffect(() => {
        fetchLatestData(
            "hotles/get/all/list",
            {},
            (data) => {
                setHotelsList(data)
            },
            (err) => {
                console.log(err);
                alert("Error fetching")
            }
        )

        fetchLatestData(
            "web/voucher/toggle-status/by-id",
            {},
            (data) => {
                setChecked(data && data.status)
            },
            (err) => {
                console.log(err)
            }
        )

    }, [])

    // console.log("================================", checked)

    const makkahHotels = hotelsList.filter((item) => item.city === `MAKKAH`)
    const madinaHotels = hotelsList.filter((item) => item.city === `MADINA`)


    // if (checked) {
    //     return <Loader />
    // }

    // console.log("================================", checked)

    if (checked) {
        return <SuspendPage />
    }

    return (
        <div>
            <Navbar
                className=""
                color="primary"
                dark
            >
                <NavbarBrand href="/">
                    {/* <img
                        alt="logo"
                        src={require("../img/connect_logoNav.png")}
                        style={{
                            height: 40,
                            width: 40
                        }}
                    /> */}
                    <span style={{ fontSize: "17px", fontWeight: "bold", marginLeft: "5px" }}>
                        HAMSYL TRAVEL AND TOUR LTD</span>
                </NavbarBrand>
            </Navbar>
            {/* {JSON.stringify(passportData)} */}
            <div className="questionnaire-container">
                {/* <h2>Monitization</h2> */}
                <form onSubmit={handleSubmit}>
                    {/* Step Progress Bar */}

                    <Progress striped animated className="mt-3" value={(currentStep / 4) * 100}>
                        {/* <Progress bar striped value="33.3" color="success">
                            AGENT
                        </Progress>
                        <Progress bar striped value="33.3" color="info">
                            MADINA
                        </Progress>
                        <Progress bar striped value="33.3" color="primary">
                            MAKKAH
                        </Progress> */}
                    </Progress>


                    <Row>
                        {currentStep === 1 && (
                            <>
                                <Col sm={6}>
                                    <Label>AGENT NAME</Label>
                                    <Input
                                        type="text"
                                        name="agentName"
                                        value={formData.agentName}
                                        onChange={handleChange}
                                    />
                                </Col>

                                <Col sm={6}>
                                    <Label>AIRLINE</Label>
                                    <Input
                                        type="text"
                                        name="airline"
                                        value={formData.airline}
                                        onChange={handleChange}
                                    />
                                </Col>

                                {passportData.map((passport, index) => (
                                    // <Row key={index}>
                                    < >
                                        <Col md={6}>
                                            <Label>NAME</Label>
                                            <Input
                                                type="text"
                                                value={passport.name}
                                                onChange={(e) => handlePassportChange(index, 'name', e.target.value)}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Label>PASSPORT</Label>
                                            <InputGroup>
                                                <Input
                                                    type="text"
                                                    value={passport.passport}
                                                    onChange={(e) => handlePassportChange(index, 'passport', e.target.value)}
                                                />
                                                <Button onClick={handleAddPassport} className='bg bg-primary'
                                                    style={{ cursor: "pointer" }}>
                                                    <FaPlusSquare size={15} />
                                                </Button>

                                                <Button onClick={() => handleRemovePassport(index)}
                                                    style={{ cursor: "pointer" }}>
                                                    <FaMinusSquare size={15} />
                                                </Button>
                                            </InputGroup>
                                        </Col>
                                    </>
                                    // </Row>
                                ))}

                            </>
                        )}

                        {currentStep === 2 && (
                            <>
                                <Col sm={6}>
                                    <Label>CITY (MADINA)</Label>
                                    <Input
                                        type="text"
                                        name="cityMadina"
                                        value={formData.cityMadina}
                                        disabled
                                    />
                                </Col>

                                <Col sm={6}>
                                    <Label>NO OF NIGHTS (MADINA)</Label>
                                    <Input
                                        type="number"
                                        name="nightsMadina"
                                        value={formData.nightsMadina}
                                        onChange={handleChange}
                                    />
                                </Col>

                                <Col sm={6}>
                                    <Label>ROOM TYPE (MADINA)</Label>
                                    <Input
                                        type="select"
                                        name="roomTypeMadina"
                                        value={formData.roomTypeMadina}
                                        onChange={handleChange}
                                    >
                                        <option> --- select --- </option>
                                        <option>SINGLE</option>
                                        <option>SHARING</option>
                                    </Input>
                                </Col>

                                <Col sm={6}>
                                    <Label>HOTEL (MADINA)</Label>
                                    <Input
                                        type="select"
                                        name="hotelMadina"
                                        value={formData.hotelMadina}
                                        onChange={handleChange}
                                    >
                                        <option>--select--</option>
                                        {madinaHotels.map((item) => (
                                            <option key={item.id}>{item.name}</option>
                                        ))}
                                    </Input>
                                </Col>

                                <Col sm={6}>
                                    <Label>CHECK IN (MADINA)</Label>
                                    <Input
                                        type="date"
                                        name="checkInMadina"
                                        value={formData.checkInMadina}
                                        onChange={handleChange}
                                    />
                                </Col>

                                <Col sm={6}>
                                    <Label>CHECK OUT (MADINA)</Label>
                                    <Input
                                        type="date"
                                        name="checkOutMadina"
                                        value={formData.checkOutMadina}
                                        onChange={handleChange}
                                    />
                                </Col>
                            </>
                        )}

                        {currentStep === 3 && (
                            <>
                                <Col sm={6}>
                                    <Label>CITY (MAKKAH)</Label>
                                    <Input
                                        type="text"
                                        name="cityMakkah"
                                        value={formData.cityMakkah}
                                        disabled
                                    />
                                </Col>

                                <Col sm={6}>
                                    <Label>NO OF NIGHTS (MAKKAH)</Label>
                                    <Input
                                        type="number"
                                        name="nightsMakkah"
                                        value={formData.nightsMakkah}
                                        onChange={handleChange}
                                    />
                                </Col>

                                <Col sm={6}>
                                    <Label>ROOM TYPE (MAKKAH)</Label>
                                    <Input
                                        type="select"
                                        name="roomTypeMakkah"
                                        value={formData.roomTypeMakkah}
                                        onChange={handleChange}
                                    >
                                        <option> --- select --- </option>
                                        <option>SINGLE</option>
                                        <option>SHARING</option>
                                    </Input>
                                </Col>

                                <Col sm={6}>
                                    <Label>HOTEL (MAKKAH)</Label>
                                    <Input
                                        type="select"
                                        name="hotelMakkah"
                                        value={formData.hotelMakkah}
                                        onChange={handleChange}
                                    >
                                        <option>--select--</option>
                                        {makkahHotels.map((item) => (
                                            <option key={item.id}>{item.name}</option>
                                        ))}
                                    </Input>
                                </Col>

                                <Col sm={6}>
                                    <Label>CHECK IN (MAKKAH)</Label>
                                    <Input type='date' name='checkInMakkah'
                                        value={formData.checkInMakkah} onChange={handleChange} />
                                </Col>

                                <Col sm={6}>
                                    <Label>CHECK OUT (MAKKAH)</Label>
                                    <Input type='date' name='checkOutMakkah' value={formData.checkOutMakkah}
                                        onChange={handleChange} />
                                </Col></>
                        )}

                        {currentStep === 4 && (
                            <>
                                <Col sm={6}>
                                    <Label>TRANSPORTATION TYPE</Label>
                                    <Input type='select' name='transportationType' value={formData.transportationType}
                                        onChange={handleChange} >
                                        <option> --- select --- </option>
                                        <option>  BUS  </option>
                                        <option>  SEDAN  </option>
                                        <option>  VIP  </option>
                                    </Input>
                                </Col>

                                <Col sm={6}>
                                    <Label>PAYMENT METHOOD</Label>
                                    <Input type='select' name='paymentType' value={formData.paymentType}
                                        onChange={handleChange} >
                                        <option> --- select --- </option>
                                        <option>  CASH  </option>
                                        <option>  TRANSFER  </option>
                                        <option>  CARD  </option>
                                    </Input>
                                </Col>
                            </>
                        )}


                    </Row>

                    <div style={{ display: "flex", justifyContent: "space-between" }}>

                        {currentStep > 1 && (
                            <Button type="button" onClick={handlePrevious} className='bg bg-primary mr-2'>
                                <GoArrowLeft />
                                {" "}  Previous
                            </Button>
                        )}

                        {currentStep === 4 ? (
                            <Button type="submit" onClick={handleSubmit} size='20x' className='bg bg-succcess' >
                                Submit <FiSave />
                            </Button>
                        ) : (
                            <Button type="submit" onClick={handleSubmit} className='bg bg-primary'>
                                Next <GoArrowRight />
                            </Button>
                        )}

                    </div>



                </form>

                {/* <div className="step-icons">
                    <FaCheck className={currentStep >= 1 ? 'active' : ''} />
                    <FaUser className={currentStep >= 2 ? 'active' : ''} />
                    <FaCreditCard className={currentStep >= 3 ? 'active' : ''} />
                </div> */}

            </div>
        </div>
    );
}

export default WebVoucher;
